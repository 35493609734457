import React, { useContext } from 'react'
import {
  Box,
  BASE,
  BRICO,
  LG,
  PromoBubble,
  PROMO_BUBBLE_CONSTANTS,
  MarketingLabel,
  Text,
  PromoLabel,
  PROMO_LABEL_CONSTANTS
} from 'chemistry'
import { TranslateContext } from 'client/common/translator'

export const isLarge = sizes => sizes.includes('lg')

export const CardMarketingLabel = ({ labelText, url, metadata, sizes, hasCheckbox, sx, ...props }) => (
  <Box
    sx={{
      position: 'absolute',
      transformOrigin: 'top left',
      left: hasCheckbox ? '26px' : 0,
      top: 0,
      ...(isLarge(sizes)
        ? {
            [LG]: {
              transform: 'none',
              left: 'auto',
              top: 'auto',
              right: 0,
              bottom: 0
            }
          }
        : {}),
      ...sx
    }}
    {...props}
  >
    <MarketingLabel labelText={labelText} url={url} metadata={metadata} />
  </Box>
)

export const CardSponsoredLabel = () => {
  const t = useContext(TranslateContext)
  return (
    <Text color='grey.725' my='4px' fontSize='10px' lineHeight='15px' letterSpacing='0.25px'>
      {t('lister.card.sponsored')}
    </Text>
  )
}

const getVlpBubbleStyles = ({ sizes, format }) => ({
  [BASE]: { display: 'none' },
  ...(isLarge(sizes) ? { [LG]: { display: 'block', position: 'absolute' } } : {}),
  ...(format === BRICO ? { top: '4px', left: '4px' } : { top: 0, left: 0 })
})
export const CardVlpBubble = ({ title, format, lang, sizes }) => {
  return (
    <Box sx={getVlpBubbleStyles({ format, sizes })}>
      <PromoBubble
        size={PROMO_BUBBLE_CONSTANTS.SMALL}
        colorScheme={PROMO_BUBBLE_CONSTANTS.FIXED_LOW_PRICE}
        content={{ title, supertext: '' }}
        format={format}
        lang={lang}
      />
    </Box>
  )
}

const getVlpMobileLabelStyles = sizes => ({
  [BASE]: { display: 'flex' },
  ...(isLarge(sizes) ? { [LG]: { display: 'none' } } : {})
})
export const CardVlpMobileLabel = ({ sizes, format, lang }) => {
  return (
    <PromoLabel
      size={PROMO_LABEL_CONSTANTS.SIZE.small}
      variant={PROMO_LABEL_CONSTANTS.VARIANT.alwaysLowPrice}
      format={format}
      lang={lang}
      sx={getVlpMobileLabelStyles(sizes)}
    />
  )
}
