import React from 'react'
import {
  CardImage,
  CheckboxAtom,
  ChevronDown,
  highestPriorityPromo,
  Link,
  ProductCard,
  ProductStatusLabel
} from 'chemistry'
import { CardPrice } from 'client/common/components/Card/CardPrice'
import { Text } from '@chakra-ui/react'
import { CardImageLabel } from 'client/common/components/Card/CardImageLabel'
import { extractImageProps } from 'client/common/components/Card/imageProps'
import { extractPriceProps } from 'client/common/components/Card/priceProps'
import { extractDiscountProps } from 'client/common/components/Card/discountProps'
import { CardSchema } from 'client/fragments/listerFragment/components/SchemaOrg/CardSchema'
import { statusColorMapping } from 'client/common/components/Card/cardStatusColor'
import { RatingStars } from 'client/common/components/Card/RatingStars'
import { createLogger } from 'common/log'
import { CardMarketingLabel, CardSponsoredLabel, CardVlpBubble, CardVlpMobileLabel } from './CardLabel'

const DISABLED_STATUSES = ['Binnenkort verkrijgbaar', 'Bientôt disponible']
const logger = createLogger('lister/Card')

const isSponsored = labels => {
  const sponsoredValue = labels?.__cnstrc_sponsored_listings_praxis
  return sponsoredValue !== undefined && sponsoredValue !== null
}

export const Card = props => {
  const {
    sizes = ['sm', 'md', 'lg'],
    fullPriceProps,
    disableStatus,
    t: translations,
    format,
    lang,
    categoryCode,
    availabilityStatus,
    isChecked,
    onProductCheck,
    target,
    showBVRatingOnListerCards,
    withArrow = false,
    imgProps,
    marketingLabels,
    labels
  } = props
  const hasPriceError = !!fullPriceProps?.errors?.length
  if (hasPriceError) {
    logger.error(`PRICE_ERROR, product ${format} ${props?.id} has corrupted price on lister`)
  }

  const imageProps = extractImageProps(props)
  const winnerPromo = highestPriorityPromo(props)
  const priceComponentProps = extractPriceProps({ ...fullPriceProps })
  const promotionType = fullPriceProps.type
  const discountProps = extractDiscountProps(props, promotionType, winnerPromo)
  const disabledKey = disableStatus?.isDisabled && translations.temporarilyDisabled
  const isDisabledStatus = DISABLED_STATUSES.includes(availabilityStatus)
  const [marketingLabel] = Array.isArray(marketingLabels) ? marketingLabels : []

  return (
    <>
      {!categoryCode && <CardSchema {...props} />}
      <ProductCard
        sizes={sizes}
        link={props.link}
        target={target}
        disabled={disabledKey || isDisabledStatus || (onProductCheck && !isChecked)}
      >
        {onProductCheck && <CheckboxAtom isChecked={isChecked} onChange={() => onProductCheck(props.id)} />}
        <ProductCard.Image>
          <div className='relative'>
            <CardImage {...imageProps} imgProps={imgProps} />
            {!hasPriceError && <CardImageLabel disabledKey={disabledKey} discountProps={discountProps} />}
            {fullPriceProps.isVLP && (
              <CardVlpBubble title={translations.vlp} format={format} lang={lang} sizes={sizes} />
            )}
            {marketingLabel && (
              <CardMarketingLabel
                labelText={marketingLabel?.labelText}
                url={marketingLabel?.url}
                metadata={marketingLabel?.metadata}
                sizes={sizes}
                hasCheckbox={onProductCheck}
              />
            )}
          </div>
        </ProductCard.Image>
        <ProductCard.Title>
          {isSponsored(labels) && <CardSponsoredLabel />}
          <Text fontSize='sm' fontFamily='bold' wordBreak='break-word' data-testid='card-title'>
            {props?.title}
          </Text>
        </ProductCard.Title>

        <ProductCard.Extra>
          {showBVRatingOnListerCards && !!props?.reviews?.count && (
            <RatingStars
              rating={props?.reviews?.rating || 0}
              label={`(${props?.reviews?.count || '0'})`}
              productId={props?.id}
            />
          )}
          {availabilityStatus || disabledKey ? (
            <ProductStatusLabel
              disabledKey={disabledKey}
              availabilityStatus={availabilityStatus}
              color={statusColorMapping(availabilityStatus, disabledKey)}
            />
          ) : (
            <Text>{props?.category?.title}</Text>
          )}
        </ProductCard.Extra>

        {fullPriceProps.isVLP && <CardVlpMobileLabel sizes={sizes} format={format} lang={lang} />}
        {!disabledKey && <CardPrice {...priceComponentProps} promotionType={promotionType} />}
        {withArrow && (
          <Link variant='primary' target={target}>
            <ChevronDown color='white' />
          </Link>
        )}
      </ProductCard>
    </>
  )
}
